<template>
  <div class="home_bottom">
    <div class="index_bottom">
      <div class="bottom_img">
        <img src="@/assets/logo.png" alt="" />
      </div>
      <div class="bottom_name">宁夏裕德丰拍卖行</div>
    </div>
    <div class="jpzn">
      <div class="title">竞拍指南</div>
      <div class="item">
        <div class="left">
          <li>竞买规则</li>
          <li>拍卖业务</li>
          <li>联系我们</li>
        </div>
        <div class="right">
          <li>法律法规</li>
          <li>网络拍卖</li>
          <li>竞拍流程</li>
        </div>
      </div>
    </div>
    <div class="lxwm">
      <div class="title">联系我们</div>
      <div class="item">
        <li>宁夏裕德丰拍卖行</li>
        <li>联系热线: 0951-6718909   </li>
        <li>公司地址: 银川市西夏区贺兰山体育场一号</li>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.home_bottom {
  height: 200px;
  width: 100vw;
  background: #333;
  display: flex;
  font-family: "ysbth", sans-serif;
  .jpzn {
    margin-top: 30px;
    .item {
      color: #fff;
      margin-top: 20px;
      line-height: 30px;
      display: flex;
      li {
        cursor: pointer;
        &:hover {
          color: #06719d;
        }
      }
      .right {
        margin-left: 40px;
      }
    }
    .title {
      font-size: 20px;
      color: #06719d;
      font-weight: 600;
      letter-spacing: 2px;
    }
  }
  .lxwm {
    margin-top: 30px;
    margin-left: 100px;
    .item {
      color: #fff;
      margin-top: 20px;
      line-height: 30px;
      li {
        cursor: pointer;
        &:hover {
          color: #06719d;
        }
      }
    }
    .title {
      font-size: 20px;
      color: #06719d;
      font-weight: 600;
      letter-spacing: 2px;
    }
  }
  .index_bottom {
    width: 50%;
    display: flex;
    .bottom_name {
      color: #2578a0;
      font-size: 50px;
      line-height: 200px;
    }
    .bottom_img {
      margin: 50px 30px 10px 30%;
      img {
        width: 100px;
        height: 100px;
      }
    }
  }
}
</style>
