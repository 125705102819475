<template>
  <div id="app">
    <div class="index_header">
      <div class="header_img">
        <img src="@/assets/logo.png" alt="" />
      </div>
      <div class="header_name">宁夏裕德丰拍卖行</div>
      <div class="header_phone">0951-6718909   </div>
      <div class="header_search">
        <el-input
          placeholder="请输入搜索内容"
          v-model="input3"
          class="input-with-select"
        >
          <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </div>
    </div>
    <div class="index_menu">
      <div
        class="menu_item"
        :style="{
          background: menuI === i ? '#d4d4d4' : '',
          color: menuI === i ? '#000' : '#fff',
        }"
        v-for="(item, i) in menuList"
        :key="i"
        @click="handleMenu(item, i)"
      >
        {{ item.name }}
      </div>
    </div>
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
      input3: "",
      menuI: 0,
      menuList: [
        { url: "/", name: "首页" },
        { url: "/about", name: "关于我们" },
        { url: "/auctionField", name: "拍卖领域" },
        { url: "/jpzn", name: "竞买指南" },
        { url: "/lxwm", name: "联系我们" },
      ],
    };
  },
  methods: {
    handleMenu(row, i) {
      this.menuI = i;
      this.$router.push({ path: row.url });
    },
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "ysbth";
  src: url("@/assets/font/ysbth.ttf") format("truetype");
  /* 添加其他字体格式，如woff2等 */
}
body {
  margin: 0;
  overflow: hidden;
  /*滚动条整体样式*/
  ::-webkit-scrollbar {
    width: 4px;
    height: 1px;
  }
  /*滚动条滑块*/
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-image: linear-gradient(
      to bottom,
      rgba(232, 119, 174, 0.5),
      rgba(124, 115, 243, 0.5) 45% 5%
    ); /* 渐变色 */
    border-radius: 6px; /* 圆角 */
    -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1),
      inset -1px -1px 0 rgba(0, 0, 0, 0.1); /* 阴影 */
  }
  /*滚动条轨道*/
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0);
    border-radius: 10px;
    background: rgba(247, 15, 255, 0.2);
  }
}
#app {
  width: 100vw;
  height: 100vh;
  .index_menu {
    height: 55px;
    width: 100vw;
    background: rgb(7, 99, 137);
    display: flex;
    padding-left: calc((100% - 1000px) / 2);
    .menu_item {
      width: 200px;
      line-height: 55px;
      height: 55px;
      text-align: center;
      font-size: 20px;
      font-family: "ysbth", sans-serif;
      letter-spacing: 1px;
      cursor: pointer;
    }
  }
  .index_header {
    height: 120px;
    width: 100vw;
    background: #fff;
    font-family: "ysbth", sans-serif;
    display: flex;
    .header_search {
      margin: 40px 0 0 0;
    }
    .header_phone {
      font-family: 隶书;
      color: rgb(243, 148, 82);
      line-height: 120px;
      font-size: 32px;
      margin-left: 10%;
      font-weight: 600;
    }
    .header_name {
      color: #2578a0;
      font-size: 50px;
      line-height: 120px;
    }
    .header_img {
      margin: 10px 30px 10px 15%;
      img {
        width: 100px;
        height: 100px;
      }
    }
  }
}
</style>
