<template>
  <div class="home_main">
    <div class="banner">
      <el-carousel height="540px">
        <el-carousel-item v-for="item in bannerList" :key="item">
          <img :src="item" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="home_notice">
      <div class="notice_left">
        <div>
          <div class="left_title">拍卖公告<span>/Auction Noctice</span></div>
          <div class="left_notice">
            <div><img src="@/assets/left_top.png" alt="" /></div>
            <div class="left_notice_content">
              石嘴山市惠农区花园路 103
              储蓄所房地产，标的建筑面积127.6平方米，土地使用权面积 279.34
              平方米。
            </div>
            <div class="left_notice_price">参考价:6.5万元</div>
          </div>
          <div class="left_bottom_notice">
            <div class="notice_item" v-for="(item, i) in noticeList" :key="i">
              <div class="item_content">>> {{ item.content }}</div>
              <div class="item_price">
                <div class="time">{{ selectSr() }}</div>
                <div class="price">{{ item.price }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="notice_right">
        <div class="right_title">近期热拍标的</div>
        <div class="right_list">
          <div class="list_item" v-for="(item, i) in noticeRightList" :key="i">
            <div class="item_img">
              <img :src="item.img" alt="" />
            </div>
            <div class="item_content">
              <div class="conternt">{{ item.content }}</div>
              <div class="item_price">
                <div class="price">{{ item.price }}</div>
                <div class="date">{{ selectSr() }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home_pmly">
      <div class="home_title">
        <span class="fg">--</span>
        <span class="title">拍卖领域</span>
        <span class="fg">--</span>
      </div>
      <div class="pmly_menu">
        <div
          class="pmly_menu_item"
          v-for="(item, i) in pmlyMenu"
          :key="i"
          @click="handlePmlyMenu(i)"
        >
          <div
            class="item"
            :style="{ color: pmlyMenuI === i ? '#f6b26b' : '' }"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <div class="pmly_notice_list">
        <el-carousel :interval="4000" type="card" height="300px">
          <el-carousel-item v-for="(item, i) in pmlyList" :key="i">
            <img :src="item.img" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="pmly_btn">
        <el-button type="primary" @click="findMore">查看更多</el-button>
      </div>
    </div>
    <div class="about_us">
      <div class="about_left">
        <div class="img">
          <img src="@/assets/about_us.png" alt="" />
        </div>
        <div class="about_us_content">
          成立至今，我公司拍卖标的涉及:房地产、国有土地使用权、股权、古董艺术品、无形资产、机械设备及废旧物资等类型。拍卖委托方包括各级法院、国资委、政府部门、银行、资产管理公司、企业及个人。业务涉及地域广泛覆盖全区，并在吴忠、永宁、平罗、固原、中卫、中宁、灵武、同心、大武口、青铜峡、西吉等地多次成功举办拍卖活动，受到委托方一致好评。近几年我公司还承接各级政府、金融机构、企业的公车拍卖，曾为中国农业银行股份有限公司宁夏分行以及下设的各支行成功拍卖过百余台机动车，包括武装押运车辆二十余台、单位车改车辆百余台等，均价成交，得到了委托方的肯定及好评;接受盐池县财政局的公车拍卖，近五十余台机动车也相继成交;接受外资企业嘉士伯啤酒生产企业的委托对其公司旗下的近百台机动车进行处置，最终的结果也同样得到了委托方的肯定与赞赏。我公司对于每一宗拍卖业务始终本着积极主动、认真负责的态度，对拍卖标的的真实性、合法性进行认真审核，认真研究拍卖标的的类型和特点，进行详细、客观的市场调研，确定合理的招商范围和方式，积极主动的寻找潜在竞买人，严格按照合法程序和规定举行拍卖会，采用现场拍卖、网络拍卖、现场与网络同步拍卖等多种拍卖方式促成拍卖标的以较理想的价格成交，并且保持较高的成交率。
        </div>
        <div class="about_btn">
          <el-button type="primary" size="mini" @click="handleAbout"
            >前往</el-button
          >
        </div>
      </div>
      <div class="about_right">
        <div class="right_img">
          <div class="left">
            <img src="@/assets/about1.png" alt="" />
          </div>
          <div class="right">
            <img src="@/assets/about2.png" alt="" />
          </div>
        </div>
        <div class="about_qyzz">
          <div class="qyzz_title">企业资质</div>
          <div class="qyzz_item">
            <div class="item">
              <span>›</span> 获得国家级及宁夏、银川市“守合同重信用”企业称号
            </div>
            <div class="item">
              <span>›</span> 获得国家级及宁夏、银川市“守合同重信用”企业称号
            </div>
            <div class="item">
              <span>›</span> 获得国家级及宁夏、银川市“守合同重信用”企业称号
            </div>
            <div class="item">
              <span>›</span> 获得国家级及宁夏、银川市“守合同重信用”企业称号
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tell_me">
      <img src="@/assets/tellme.png" alt="" />
    </div>
    <homeBottom></homeBottom>
  </div>
</template>

<script>
import homeBottom from "@/components/homeBottom.vue";
export default {
  components: {
    homeBottom,
  },
  data() {
    return {
      pmlyMenuI: 0,
      pmlyMenu: [
        "房产拍卖",
        "车辆拍卖",
        "资产物资",
        "艺术品",
        "农产品",
        "成交结果公示",
      ],
      bannerList: [
        require("@/assets/banner1.png"),
        require("@/assets/banner.png"),
      ],
      pmlyList: [
        {
          img: require("@/assets/img1.png"),
          content:
            "平罗县世名颐和花园27号楼1单元401号住宅房，建筑面积87.04㎡，总层数6层，所在楼层4层。",
          price: "参考价：19.15万元",
        },
        {
          img: require("@/assets/img2.png"),
          content:
            "罗县世名颐和花园27号楼1单元402号住宅房，建筑面积87.04㎡，总层数6层，所在楼层4层。",
          price: "参考价：19.15万元",
        },
        {
          img: require("@/assets/img3.png"),
          content:
            "平罗县世名颐和花园46号楼1单元401号住宅房，建筑面积102.23㎡，总层数6层，所在楼层4层。",
          price: "参考价：22.5万元",
        },
        {
          img: require("@/assets/img4.png"),
          content:
            "平罗县世名颐和花园46号楼2单元401号住宅房，建筑面积102.23㎡，总层数6层，所在楼层4层。",
          price: "参考价：22.5万元",
        },
        {
          img: require("@/assets/img5.png"),
          content:
            "平罗县世名颐和花园46号楼2单元402号住宅房，建筑面积102.23㎡，总层数6层，所在楼层4层。",
          price: "参考价：22.5万元",
        },
      ],
      noticeRightList: [
        {
          img: require("@/assets/img1.png"),
          content:
            "平罗县世名颐和花园27号楼1单元401号住宅房，建筑面积87.04㎡，总层数6层，所在楼层4层。",
          price: "参考价：19.15万元",
        },
        {
          img: require("@/assets/img2.png"),
          content:
            "罗县世名颐和花园27号楼1单元402号住宅房，建筑面积87.04㎡，总层数6层，所在楼层4层。",
          price: "参考价：19.15万元",
        },
        {
          img: require("@/assets/img3.png"),
          content:
            "平罗县世名颐和花园46号楼1单元401号住宅房，建筑面积102.23㎡，总层数6层，所在楼层4层。",
          price: "参考价：22.5万元",
        },
        {
          img: require("@/assets/img4.png"),
          content:
            "平罗县世名颐和花园46号楼2单元401号住宅房，建筑面积102.23㎡，总层数6层，所在楼层4层。",
          price: "参考价：22.5万元",
        },
        {
          img: require("@/assets/img5.png"),
          content:
            "平罗县世名颐和花园46号楼2单元402号住宅房，建筑面积102.23㎡，总层数6层，所在楼层4层。",
          price: "参考价：22.5万元",
        },
        {
          img: require("@/assets/img1.png"),
          content:
            "大武口区鸣沙路79幢F区114、301号商业房，建筑面积分别为504.9㎡、545.95㎡，总层数3层，所在楼层1-3层。",
          price: "参考价：334.84万元",
        },
        {
          img: require("@/assets/img2.png"),
          content:
            "贺兰县习岗镇和平六社以北祥和家苑S-3号商业楼118号房，建筑面积594.79㎡，总层数3层，所在楼层1层。",
          price: "参考价：224.79万元",
        },
        {
          img: require("@/assets/img3.png"),
          content:
            "平罗县星海北苑19号商住楼3号商业房，建筑面积234.54㎡，共6层，所在楼层为1-2层。",
          price: "参考价：93万元",
        },
        {
          img: require("@/assets/img4.png"),
          content:
            "大武口区黄河西街490号商业房，面积181.9㎡，总层数6层，所在层数1-2层。",
          price: "参考价：75万元",
        },
        {
          img: require("@/assets/img5.png"),
          content:
            "平罗县鼓楼东街南侧东苑街东侧商业楼（原平罗县中世大酒店），面积9103.3㎡，总层数9层，所在层数1-9层。",
          price: "参考价：2974.74万元",
        },
        {
          img: require("@/assets/img1.png"),
          content:
            "平罗县怀远大街1号商住楼3号商业房，面积273.68㎡，总层数11层，所在层数1-2层。",
          price: "参考价：96.73万元",
        },
        {
          img: require("@/assets/img2.png"),
          content:
            "平罗县怀远大街206号营业房（锦绣华城小区），建筑面积560.15㎡，总层数2层，所在层数1层。",
          price: "参考价：202.23万元",
        },
      ],
      noticeList: [
        {
          content:
            "隆德县渝河路南渝河商贸广场 2 号楼 00136-00215房产（1-2层），标的总建筑面积185.43平方米（原隆德城关分理处）。",
          price: "参考价：96.91万元",
        },
        {
          content:
            "隆德县好水乡街道中国农业银行股份有限公司隆德县支行 00101号房产，标的总建筑面积为306.28 平方米，土地使用权面积1466.48平方米。（原隆德好水分理处）",
          price: "参考价：49.93万元",
        },
        {
          content:
            "平罗县世名颐和花园27号楼4单元401号住宅房，建筑面积87.04㎡，总层数6层，所在楼层4层。",
          price: "参考价：19.15万元",
        },
      ],
    };
  },
  methods: {
    handleAbout() {
      this.$router.push({ path: "/about" });
    },
    findMore() {
      this.$router.push({ path: "/auctionField" });
    },
    handlePmlyMenu(i) {
      this.pmlyMenuI = i;
    },
    selectSr() {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // 月份从 0 开始，需要加 1
      const day = date.getDate();
      return `${year}-${month}-${day}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.home_main {
  height: calc(100vh - 175px);
  overflow: scroll;
  .tell_me {
    img {
      width: 100vw;
      height: auto;
    }
  }
  .about_us {
    width: 70%;
    margin: 30px 15%;
    display: flex;
    .about_left {
      width: 50%;
      .about_btn {
        margin-top: 10px;
        text-align: center;
      }
      .about_us_content {
        width: 100%;
        height: 300px;
        line-height: 25px;
        text-indent: 2em; /* 2个空格的宽度 */
        overflow: hidden; /* 隐藏溢出部分 */
        text-overflow: ellipsis; /* 显示省略号 */
      }
      .img {
        width: 100%;
        margin: 0 10%;
        img {
          width: 80%;
          height: 120px;
        }
      }
    }
    .about_right {
      width: 49%;
      margin-left: 1%;
      .about_qyzz {
        border: 1px solid #eee;
        height: 280px;
        .qyzz_item {
          .item {
            height: 50px;
            line-height: 50px;
            border-bottom: 1px dashed rgba(0, 0, 0, 0.5);
            span {
              margin: 0 20px;
            }
          }
        }
        .qyzz_title {
          height: 40px;
          background-color: #06719d;
          line-height: 40px;
          color: #fff;
          padding-left: 10px;
        }
      }
      .right_img {
        display: flex;
        .left {
          width: 100%;
          img {
            width: 90%;
            height: 180px;
          }
        }
        .right {
          width: 100%;

          img {
            width: 100%;
            height: 180px;
          }
        }
      }
    }
  }
  .home_title {
    width: 100vw;
    text-align: center;
    color: #06719d;
    font-size: 24px;
    font-weight: 600;
    .title {
      margin: 0 20px;
    }
  }
  .home_pmly {
    width: 100vw;
    min-height: 300px;
    background: #e0f1f8;
    padding: 30px;
    .pmly_btn {
      text-align: center;
    }
    .pmly_notice_list {
      margin: 30px 20%;
      width: 60%;
      min-height: 200px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .pmly_menu {
      margin: 30px 25% 0 25%;
      display: flex;
      width: 50%;
      .pmly_menu_item {
        width: calc(100% / 6);
        margin: 0 10px;
        cursor: pointer;
        .item {
          height: 40px;
          text-align: center;
          line-height: 40px;
          background: #06719d;
          border-radius: 7px;
          color: #fff;
          &:hover {
            color: #f6b26b;
          }
        }
      }
    }
  }
  .home_notice {
    width: 60vw;
    min-height: 30px;
    margin: 40px 20vw 40px 20vw;
    display: flex;
    .notice_right {
      width: 49%;
      margin-left: 1%;
      .right_list {
        width: 100%;
        height: 556px;
        overflow-y: auto;
        margin-top: 10px;
        .list_item {
          width: 100%;
          display: flex;
          position: relative;
          .item_price {
            position: absolute;
            display: flex;
            bottom: 10px;
            width: 67%;
            .price {
              font-weight: 600;
            }
            .date {
              margin-left: auto;
              color: rgba(0, 0, 0, 0.5);
            }
          }
          .item_content {
            margin: 10px;
          }
          .item_img {
            margin: 10px 0 0 0;
            img {
              width: 150px;
              height: 90px;
              border-radius: 7px;
            }
          }
        }
      }
      .right_title {
        width: 200px;
        height: 50px;
        background: #06719d;
        text-align: center;
        line-height: 50px;
        color: #fff;
        font-size: 20px;
        &:hover {
          font-size: 16px;
        }
      }
    }
    .notice_left {
      width: 49%;
      min-height: 30px;
      background: #06719d;
      padding: 15px;
      .left_bottom_notice {
        width: calc(100% - 20px);
        height: 150px;
        padding: 10px;
        overflow-y: auto;
        background: #fff;
        margin-top: 15px;
        .notice_item {
          border-bottom: 1px solid #000;
          padding-bottom: 10px;
          margin: 10px 0;
          .item_content {
            line-height: 25px;
          }
          .item_price {
            display: flex;
            margin-top: 5px;
            .time {
              color: rgba(0, 0, 0, 0.5);
            }
            .price {
              font-weight: 600;
              margin-left: auto;
            }
          }
        }
      }
      .left_notice {
        width: calc(100% - 20px);
        padding: 10px;
        background: #fff;
        margin-top: 10px;
        .left_notice_price {
          margin: 10px;
          font-weight: 600;
          font-size: 20px;
        }
        .left_notice_content {
          margin: 10px;
          text-indent: 2em; /* 2个空格的宽度 */
        }
        img {
          width: 100%;
          height: 230px;
          border-radius: 7px;
        }
      }
      .left_title {
        color: #f6b26b;
        font-size: 22px;
        span {
          margin-left: 5px;
          font-size: 14px;
          color: #fff;
        }
      }
    }
  }
}
</style>
