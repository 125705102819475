import Vue from "vue";
import VueRouter from "vue-router";
import ydfHome from "../views/ydfHome.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: ydfHome,
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/aboutUs.vue"),
  },
  {
    path: "/auctionField",
    name: "auctionField",
    component: () => import("../views/auctionField.vue"),
  },
  {
    path: "/jpzn",
    name: "jpzn",
    component: () => import("../views/jpzn.vue"),
  },
  {
    path: "/lxwm",
    name: "lxwm",
    component: () => import("../views/lxwm.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
